import { default as _91id_93ti2NrmNxaCMeta } from "/workspace/candidate/ui/pages/auth/reset/[id].vue?macro=true";
import { default as _91slug_93K46PPXfOASMeta } from "/workspace/candidate/ui/pages/blog/[slug].vue?macro=true";
import { default as indexgcFjNKpQKsMeta } from "/workspace/candidate/ui/pages/blog/index.vue?macro=true";
import { default as blogNE1FUe0oCkMeta } from "/workspace/candidate/ui/pages/blog.vue?macro=true";
import { default as _91id_93yM6GbKGjeoMeta } from "/workspace/candidate/ui/pages/candidates/[id].vue?macro=true";
import { default as index1g8g7qiKBxMeta } from "/workspace/candidate/ui/pages/candidates/index.vue?macro=true";
import { default as indexIzjnx99rE2Meta } from "/workspace/candidate/ui/pages/changelog/index.vue?macro=true";
import { default as cvparser7C3rmSKqbBMeta } from "/workspace/candidate/ui/pages/cvparser.vue?macro=true";
import { default as _91_46_46_46slug_93SGtlP9y8GKMeta } from "/workspace/candidate/ui/pages/docs/[...slug].vue?macro=true";
import { default as docsUyUYanlPUMMeta } from "/workspace/candidate/ui/pages/docs.vue?macro=true";
import { default as forgotpwikaTN6C1u6Meta } from "/workspace/candidate/ui/pages/forgotpw.vue?macro=true";
import { default as indexVBIN3GB5gHMeta } from "/workspace/candidate/ui/pages/index.vue?macro=true";
import { default as loginVtrV3XeIxaMeta } from "/workspace/candidate/ui/pages/login.vue?macro=true";
import { default as monitorg8BZOAHiUjMeta } from "/workspace/candidate/ui/pages/monitor.vue?macro=true";
import { default as pricinghskorA6Au1Meta } from "/workspace/candidate/ui/pages/pricing.vue?macro=true";
import { default as resetbmmpDHcnIdMeta } from "/workspace/candidate/ui/pages/reset.vue?macro=true";
import { default as SettingstoVx40U6JWMeta } from "/workspace/candidate/ui/pages/Settings.vue?macro=true";
import { default as ApplicationUserSettingsA9y3TZCP2KMeta } from "/workspace/candidate/ui/pages/settings/ApplicationUserSettings.vue?macro=true";
import { default as CompanySettingsXe9KLcxKAqMeta } from "/workspace/candidate/ui/pages/settings/CompanySettings.vue?macro=true";
import { default as OverallUsageC4UwFL11b0Meta } from "/workspace/candidate/ui/pages/settings/OverallUsage.vue?macro=true";
import { default as PersonalSettingsWxA9PwlkUyMeta } from "/workspace/candidate/ui/pages/settings/PersonalSettings.vue?macro=true";
import { default as SubscriptionSettingsl01mL4sH6KMeta } from "/workspace/candidate/ui/pages/settings/SubscriptionSettings.vue?macro=true";
import { default as TemplateSettingsTo6iXWX34LMeta } from "/workspace/candidate/ui/pages/settings/TemplateSettings.vue?macro=true";
import { default as signupz2EfMajxNfMeta } from "/workspace/candidate/ui/pages/signup.vue?macro=true";
import { default as _91slug_93DYaqsATgTiMeta } from "/workspace/candidate/ui/pages/whathappenedlastnight/[slug].vue?macro=true";
import { default as indexjEaXp9kuE6Meta } from "/workspace/candidate/ui/pages/whathappenedlastnight/index.vue?macro=true";
import { default as component_45stubUSLkCBQEzKMeta } from "/workspace/candidate/ui/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91id_93ti2NrmNxaCMeta?.name ?? "auth-reset-id",
    path: _91id_93ti2NrmNxaCMeta?.path ?? "/auth/reset/:id()",
    meta: _91id_93ti2NrmNxaCMeta || {},
    alias: _91id_93ti2NrmNxaCMeta?.alias || [],
    redirect: _91id_93ti2NrmNxaCMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/auth/reset/[id].vue").then(m => m.default || m)
  },
  {
    name: blogNE1FUe0oCkMeta?.name ?? undefined,
    path: blogNE1FUe0oCkMeta?.path ?? "/blog",
    meta: blogNE1FUe0oCkMeta || {},
    alias: blogNE1FUe0oCkMeta?.alias || [],
    redirect: blogNE1FUe0oCkMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/blog.vue").then(m => m.default || m),
    children: [
  {
    name: _91slug_93K46PPXfOASMeta?.name ?? "blog-slug",
    path: _91slug_93K46PPXfOASMeta?.path ?? ":slug()",
    meta: _91slug_93K46PPXfOASMeta || {},
    alias: _91slug_93K46PPXfOASMeta?.alias || [],
    redirect: _91slug_93K46PPXfOASMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgcFjNKpQKsMeta?.name ?? "blog",
    path: indexgcFjNKpQKsMeta?.path ?? "",
    meta: indexgcFjNKpQKsMeta || {},
    alias: indexgcFjNKpQKsMeta?.alias || [],
    redirect: indexgcFjNKpQKsMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/blog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93yM6GbKGjeoMeta?.name ?? "candidates-id",
    path: _91id_93yM6GbKGjeoMeta?.path ?? "/candidates/:id()",
    meta: _91id_93yM6GbKGjeoMeta || {},
    alias: _91id_93yM6GbKGjeoMeta?.alias || [],
    redirect: _91id_93yM6GbKGjeoMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/candidates/[id].vue").then(m => m.default || m)
  },
  {
    name: index1g8g7qiKBxMeta?.name ?? "candidates",
    path: index1g8g7qiKBxMeta?.path ?? "/candidates",
    meta: index1g8g7qiKBxMeta || {},
    alias: index1g8g7qiKBxMeta?.alias || [],
    redirect: index1g8g7qiKBxMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: indexIzjnx99rE2Meta?.name ?? "changelog",
    path: indexIzjnx99rE2Meta?.path ?? "/changelog",
    meta: indexIzjnx99rE2Meta || {},
    alias: indexIzjnx99rE2Meta?.alias || [],
    redirect: indexIzjnx99rE2Meta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/changelog/index.vue").then(m => m.default || m)
  },
  {
    name: cvparser7C3rmSKqbBMeta?.name ?? "cvparser",
    path: cvparser7C3rmSKqbBMeta?.path ?? "/cvparser",
    meta: cvparser7C3rmSKqbBMeta || {},
    alias: cvparser7C3rmSKqbBMeta?.alias || [],
    redirect: cvparser7C3rmSKqbBMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/cvparser.vue").then(m => m.default || m)
  },
  {
    name: docsUyUYanlPUMMeta?.name ?? "docs",
    path: docsUyUYanlPUMMeta?.path ?? "/docs",
    meta: docsUyUYanlPUMMeta || {},
    alias: docsUyUYanlPUMMeta?.alias || [],
    redirect: docsUyUYanlPUMMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/docs.vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46slug_93SGtlP9y8GKMeta?.name ?? "docs-slug",
    path: _91_46_46_46slug_93SGtlP9y8GKMeta?.path ?? ":slug(.*)*",
    meta: _91_46_46_46slug_93SGtlP9y8GKMeta || {},
    alias: _91_46_46_46slug_93SGtlP9y8GKMeta?.alias || [],
    redirect: _91_46_46_46slug_93SGtlP9y8GKMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/docs/[...slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: forgotpwikaTN6C1u6Meta?.name ?? "forgotpw",
    path: forgotpwikaTN6C1u6Meta?.path ?? "/forgotpw",
    meta: forgotpwikaTN6C1u6Meta || {},
    alias: forgotpwikaTN6C1u6Meta?.alias || [],
    redirect: forgotpwikaTN6C1u6Meta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/forgotpw.vue").then(m => m.default || m)
  },
  {
    name: indexVBIN3GB5gHMeta?.name ?? "index",
    path: indexVBIN3GB5gHMeta?.path ?? "/",
    meta: indexVBIN3GB5gHMeta || {},
    alias: indexVBIN3GB5gHMeta?.alias || [],
    redirect: indexVBIN3GB5gHMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginVtrV3XeIxaMeta?.name ?? "login",
    path: loginVtrV3XeIxaMeta?.path ?? "/login",
    meta: loginVtrV3XeIxaMeta || {},
    alias: loginVtrV3XeIxaMeta?.alias || [],
    redirect: loginVtrV3XeIxaMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: monitorg8BZOAHiUjMeta?.name ?? "monitor",
    path: monitorg8BZOAHiUjMeta?.path ?? "/monitor",
    meta: monitorg8BZOAHiUjMeta || {},
    alias: monitorg8BZOAHiUjMeta?.alias || [],
    redirect: monitorg8BZOAHiUjMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/monitor.vue").then(m => m.default || m)
  },
  {
    name: pricinghskorA6Au1Meta?.name ?? "pricing",
    path: pricinghskorA6Au1Meta?.path ?? "/pricing",
    meta: pricinghskorA6Au1Meta || {},
    alias: pricinghskorA6Au1Meta?.alias || [],
    redirect: pricinghskorA6Au1Meta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: resetbmmpDHcnIdMeta?.name ?? "reset",
    path: resetbmmpDHcnIdMeta?.path ?? "/reset",
    meta: resetbmmpDHcnIdMeta || {},
    alias: resetbmmpDHcnIdMeta?.alias || [],
    redirect: resetbmmpDHcnIdMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: SettingstoVx40U6JWMeta?.name ?? "Settings",
    path: SettingstoVx40U6JWMeta?.path ?? "/Settings",
    meta: SettingstoVx40U6JWMeta || {},
    alias: SettingstoVx40U6JWMeta?.alias || [],
    redirect: SettingstoVx40U6JWMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/Settings.vue").then(m => m.default || m)
  },
  {
    name: ApplicationUserSettingsA9y3TZCP2KMeta?.name ?? "settings-ApplicationUserSettings",
    path: ApplicationUserSettingsA9y3TZCP2KMeta?.path ?? "/settings/ApplicationUserSettings",
    meta: ApplicationUserSettingsA9y3TZCP2KMeta || {},
    alias: ApplicationUserSettingsA9y3TZCP2KMeta?.alias || [],
    redirect: ApplicationUserSettingsA9y3TZCP2KMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/ApplicationUserSettings.vue").then(m => m.default || m)
  },
  {
    name: CompanySettingsXe9KLcxKAqMeta?.name ?? "settings-CompanySettings",
    path: CompanySettingsXe9KLcxKAqMeta?.path ?? "/settings/CompanySettings",
    meta: CompanySettingsXe9KLcxKAqMeta || {},
    alias: CompanySettingsXe9KLcxKAqMeta?.alias || [],
    redirect: CompanySettingsXe9KLcxKAqMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/CompanySettings.vue").then(m => m.default || m)
  },
  {
    name: OverallUsageC4UwFL11b0Meta?.name ?? "settings-OverallUsage",
    path: OverallUsageC4UwFL11b0Meta?.path ?? "/settings/OverallUsage",
    meta: OverallUsageC4UwFL11b0Meta || {},
    alias: OverallUsageC4UwFL11b0Meta?.alias || [],
    redirect: OverallUsageC4UwFL11b0Meta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/OverallUsage.vue").then(m => m.default || m)
  },
  {
    name: PersonalSettingsWxA9PwlkUyMeta?.name ?? "settings-PersonalSettings",
    path: PersonalSettingsWxA9PwlkUyMeta?.path ?? "/settings/PersonalSettings",
    meta: PersonalSettingsWxA9PwlkUyMeta || {},
    alias: PersonalSettingsWxA9PwlkUyMeta?.alias || [],
    redirect: PersonalSettingsWxA9PwlkUyMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/PersonalSettings.vue").then(m => m.default || m)
  },
  {
    name: SubscriptionSettingsl01mL4sH6KMeta?.name ?? "settings-SubscriptionSettings",
    path: SubscriptionSettingsl01mL4sH6KMeta?.path ?? "/settings/SubscriptionSettings",
    meta: SubscriptionSettingsl01mL4sH6KMeta || {},
    alias: SubscriptionSettingsl01mL4sH6KMeta?.alias || [],
    redirect: SubscriptionSettingsl01mL4sH6KMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/SubscriptionSettings.vue").then(m => m.default || m)
  },
  {
    name: TemplateSettingsTo6iXWX34LMeta?.name ?? "settings-TemplateSettings",
    path: TemplateSettingsTo6iXWX34LMeta?.path ?? "/settings/TemplateSettings",
    meta: TemplateSettingsTo6iXWX34LMeta || {},
    alias: TemplateSettingsTo6iXWX34LMeta?.alias || [],
    redirect: TemplateSettingsTo6iXWX34LMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/settings/TemplateSettings.vue").then(m => m.default || m)
  },
  {
    name: signupz2EfMajxNfMeta?.name ?? "signup",
    path: signupz2EfMajxNfMeta?.path ?? "/signup",
    meta: signupz2EfMajxNfMeta || {},
    alias: signupz2EfMajxNfMeta?.alias || [],
    redirect: signupz2EfMajxNfMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DYaqsATgTiMeta?.name ?? "whathappenedlastnight-slug",
    path: _91slug_93DYaqsATgTiMeta?.path ?? "/whathappenedlastnight/:slug()",
    meta: _91slug_93DYaqsATgTiMeta || {},
    alias: _91slug_93DYaqsATgTiMeta?.alias || [],
    redirect: _91slug_93DYaqsATgTiMeta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/whathappenedlastnight/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjEaXp9kuE6Meta?.name ?? "whathappenedlastnight",
    path: indexjEaXp9kuE6Meta?.path ?? "/whathappenedlastnight",
    meta: indexjEaXp9kuE6Meta || {},
    alias: indexjEaXp9kuE6Meta?.alias || [],
    redirect: indexjEaXp9kuE6Meta?.redirect,
    component: () => import("/workspace/candidate/ui/pages/whathappenedlastnight/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUSLkCBQEzKMeta?.name ?? undefined,
    path: component_45stubUSLkCBQEzKMeta?.path ?? "/docs",
    meta: component_45stubUSLkCBQEzKMeta || {},
    alias: component_45stubUSLkCBQEzKMeta?.alias || [],
    redirect: component_45stubUSLkCBQEzKMeta?.redirect,
    component: () => import("/workspace/candidate/ui/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]