import {defineStore} from 'pinia';
import {persistedState} from "../.nuxt/imports";


export const useTableFilterStorage = defineStore({
  id: 'tableFilterStorage',
  state: () => {
    return {
      newOrExisting: [], //available values: "Bestand", "Neueingang"
      stati: [],
      recruiter: [],
    }
  }, persist: {
    storage: persistedState.localStorage,
  },
  actions: {
    reset() {
      this.newOrExisting = [];
      this.stati = [];
      this.recruiter = [];
    }
  },
})
